* {
	font-family: 'Open Sans', sans-serif;
}

input {
	/* font-family: 'Open Sans', sans-serif; */
	font-family: 'Open Sans', sans-serif;
}
body {
	background-color: #fff;

	font-size: 13px;
}

p {
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Roboto';
	padding: 0;
	margin: 0;
}

h3 {
	font-size: 1.25rem;
	font-weight: 500;
}

h4 {
	font-size: 1rem;
}
* {
	box-sizing: border-box;
}
h1 {
	padding: 0;
	margin: 0;
}

.showEnterModal-enter {
	height: 0px;
}
.showEnterModal-enter-active {
	height: 100%;
	transition: height 200ms;
}
.showEnterModal-exit {
	height: 100%;
}
.showEnterModal-exit-active {
	height: 0px;
	transition: height 200ms;
}

.btn:disabled {
	opacity: 0.4;
}
